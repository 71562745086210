.generalSpeechEvaluation___3AjVQ {
  width: 984px!important;
  margin: 0 auto;
}
.generalSpeechEvaluation___3AjVQ .scenesBox___3n4iT .scenesTypesBox___2ZuJW {
  margin-bottom: 20px!important;
}
.generalSpeechEvaluation___3AjVQ .scenesBox___3n4iT .bottomLine___2_5YS {
  width: 26px!important;
}
.generalSpeechEvaluation___3AjVQ .scenesBox___3n4iT .scenesContent___O7E-4 {
  text-align: left;
  width: 100%;
  border-radius: 3px;
  padding-bottom: 14px;
}
