.ocrContainer___3HNVm .banner___1_Stp {
  position: relative;
  margin-top: -1px;
}
.ocrContainer___3HNVm .banner___1_Stp .bannnerImage___1QgXS {
  width: 100%;
  display: block;
}
.ocrContainer___3HNVm .banner___1_Stp .bannerContent___8KHpP {
  width: 100%;
  height: 104px;
  position: absolute;
  top: 185px;
  left: 0px;
  display: flex;
  justify-content: center;
}
.ocrContainer___3HNVm .banner___1_Stp .bannerContent___8KHpP .bannerContentInner___1Pvsv {
  height: 104px;
}
.ocrContainer___3HNVm .banner___1_Stp .bannerContent___8KHpP .bannerContentInner___1Pvsv p {
  font-size: 34px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #f3f6fa;
  line-height: 52px;
  text-align: center;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY {
  width: 100%;
  padding-top: 30px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ {
  width: 100%;
  background-color: #f6f6f6;
  padding-bottom: 8px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl {
  width: 1200px;
  padding: 64px 0px 86px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .titleButton___3_E1J {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .titleButton___3_E1J .title___3vqpu {
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #042044;
  line-height: 33px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .titleButton___3_E1J .downloadBtn___3juth {
  width: 133px;
  height: 40px;
  background: #5187d5;
  border-radius: 2px;
  border: 0px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #ffffff;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .description___2S-Xb {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 26px;
  margin-bottom: 41px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .content___bZ7wy {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .content___bZ7wy .fileStyle___372cX {
  width: 1200px;
  height: 600px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .content___bZ7wy .item___1l_Rv {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 20px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .content___bZ7wy .item___1l_Rv .label___3Fn2L {
  width: 144px;
  height: 34px;
  text-align: center;
  border: 1px solid #e0e0e0;
  vertical-align: middle;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .content___bZ7wy .item___1l_Rv .text___31-aO {
  width: 264px;
  height: 34px;
  padding-left: 30px;
  border: 1px solid #e0e0e0;
  vertical-align: middle;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .content___bZ7wy .verticalLine___JLtii {
  width: 2px;
  height: 200px;
  border: 1px solid #f2f2f2;
  margin: 0 40px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .content___bZ7wy .example___3zTck {
  width: 696px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .content___bZ7wy .example___3zTck .title___3vqpu {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #042044;
  line-height: 26px;
  margin-bottom: 13px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .content___bZ7wy .example___3zTck .imgbg___1z3ge {
  width: 696px;
  height: 135px;
  background: #FCFBFB;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.ocrContainer___3HNVm .datasetOuter___aPUZY .contentItem___1qtYQ .contentItemOuter___2aVk_ .contentItemInner___Y1fWl .content___bZ7wy .example___3zTck .text___31-aO {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 22px;
}
.modal___BeO9o {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal___BeO9o .modalBody___91Avv {
  width: 358px;
}
.modal___BeO9o .modalBody___91Avv .formItem___1LiqX {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal___BeO9o .modalBody___91Avv .formItem___1LiqX label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 20px;
}
.modal___BeO9o .modalBody___91Avv .formItem___1LiqX label::before {
  color: #d0021b;
}
.modal___BeO9o .modalBody___91Avv .formItem___1LiqX input {
  width: 300px;
  height: 34px;
  border-radius: 2px;
  border: 1px solid #e7e7e7;
}
.modal___BeO9o .modalBody___91Avv .formItem___1LiqX div.ant-form-explain___1jL3C {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #d0021b;
  line-height: 17px;
}
.modal___BeO9o .modalBody___91Avv .protocolOuter___1DBuK {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal___BeO9o .modalBody___91Avv .protocolOuter___1DBuK .checkbox___1MH55 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 20px;
  margin-bottom: 6px;
}
.modal___BeO9o .modalBody___91Avv .protocolOuter___1DBuK .checkbox___1MH55 a {
  color: #5187D5;
}
.modal___BeO9o .modalBody___91Avv .tipOuter___25MN3 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal___BeO9o .modalBody___91Avv .tipOuter___25MN3 .tip___3UlJa {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #d0021b;
  line-height: 17px;
  margin-bottom: 30px;
}
.modal___BeO9o .modalBody___91Avv .buttonOuter___1bOZ6 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal___BeO9o .modalBody___91Avv .buttonOuter___1bOZ6 .button___3DV9o {
  width: 124px;
  height: 40px;
  background: #5187d5;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin: 0 auto;
}
.modal___BeO9o .modalBody___91Avv .buttonOuter___1bOZ6 .buttonDisable___J1Cuv {
  background: #f6f6f6;
  color: darkgray;
}
