.upload-wrapper___1Hnsj {
  display: inline-block;
  position: relative;
  top: 5px;
  left: 2px;
}
.upload-wrapper___1Hnsj .ant-upload {
  border: none;
  background-color: #fff;
  padding: 0 !important;
}
.upload-wrapper___1Hnsj .icon-upload {
  font-size: 25px;
  pointer-events: none;
}
