.ant-modal-content{
  border-radius: 0px!important;
}
.ant-modal-header{
  height: 71px;
  padding: 28px 0px 21px 34px;
  vertical-align: middle;
  border-bottom: 2px solid rgba(246,246,246,1);
}
.ant-modal-title{
  font-size:16px;
  font-family:PingFangSC-Medium,PingFang SC,'Microsoft YaHei',Avenir,-apple-system,BlinkMacSystemFont,'Segoe UI','Hiragino Sans GB','Helvetica Neue',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol',sans-serif;
  font-weight:500;
  color:rgba(4,32,68,1);
}
.ant-modal-close-x{
  width: 71px;
  height: 71px;
  line-height: 71px;
}