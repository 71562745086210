.monitor-area___1pf9h {
  position: fixed;
  right: 0;
  top: 10vh;
  width: 200px;
  height: 400px;
  overflow: scroll;
  background-color: #fff;
  z-index: 1000;
}
