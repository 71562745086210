.banner___11Tk6 {
  position: relative;
  margin-top: -1px;
}
.banner___11Tk6 .bannnerImage___3z2ng {
  width: 100%;
  display: block;
}
.banner___11Tk6 .bannerContent___3Z6rh {
  width: 100%;
  height: 104px;
  position: absolute;
  top: 185px;
  left: 0px;
  display: flex;
  justify-content: center;
}
.banner___11Tk6 .bannerContent___3Z6rh .bannerContentInner___2O5Nu {
  height: 104px;
}
.banner___11Tk6 .bannerContent___3Z6rh .bannerContentInner___2O5Nu p {
  font-size: 34px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #f3f6fa;
  line-height: 52px;
  text-align: center;
}
.datasetOuter___XHjTW {
  width: 100%;
  padding-top: 51px;
}
.datasetOuter___XHjTW .tabBar___31D-Y {
  width: 100%;
  display: flex;
  justify-content: center;
}
.datasetOuter___XHjTW .tabBar___31D-Y .radioGroup___jfhd_ {
  width: 1200px;
}
.datasetOuter___XHjTW .tabBar___31D-Y .radioGroup___jfhd_ .radioButton___IdUqN {
  width: 600px;
  height: 50px;
  border: 1px solid #5187d5;
  text-align: center;
  vertical-align: middle;
  border-radius: 0;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #5187d5;
  line-height: 50px;
}
.datasetOuter___XHjTW .tabBar___31D-Y .radioGroup___jfhd_ .active___11orj {
  background: #5187d5;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- {
  width: 100%;
  background-color: #f6f6f6;
  padding-bottom: 8px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY {
  width: 1200px;
  padding: 64px 0px 86px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .titleButton___f6kvR {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .titleButton___f6kvR .title___2D-dV {
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #042044;
  line-height: 33px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .titleButton___f6kvR .downloadBtn___DA2xh {
  width: 133px;
  height: 40px;
  background: #5187d5;
  border-radius: 2px;
  border: 0px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #ffffff;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .description___1VYoM {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 26px;
  margin-bottom: 41px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .content___15zY_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .content___15zY_ .item___2MkaU {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 20px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .content___15zY_ .item___2MkaU .label___3que6 {
  width: 144px;
  height: 34px;
  text-align: center;
  border: 1px solid #e0e0e0;
  vertical-align: middle;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .content___15zY_ .item___2MkaU .text___2vP_o {
  width: 264px;
  height: 34px;
  padding-left: 30px;
  border: 1px solid #e0e0e0;
  vertical-align: middle;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .content___15zY_ .verticalLine___2BEwe {
  width: 2px;
  height: 200px;
  border: 1px solid #f2f2f2;
  margin: 0 40px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .content___15zY_ .example___DMx7J {
  width: 696px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .content___15zY_ .example___DMx7J .title___2D-dV {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 500;
  color: #042044;
  line-height: 26px;
  margin-bottom: 13px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .content___15zY_ .example___DMx7J .imgbg___yoOhS {
  width: 696px;
  height: 135px;
  background: #FCFBFB;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.datasetOuter___XHjTW .contentItem___1Ct8- .contentItemOuter___1BcjE .contentItemInner___2bGzY .content___15zY_ .example___DMx7J .text___2vP_o {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 22px;
}
.modal___2SrJ1 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal___2SrJ1 .modalBody___25qo_ {
  width: 358px;
}
.modal___2SrJ1 .modalBody___25qo_ .formItem___2mxJU {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal___2SrJ1 .modalBody___25qo_ .formItem___2mxJU label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 20px;
}
.modal___2SrJ1 .modalBody___25qo_ .formItem___2mxJU label::before {
  color: #d0021b;
}
.modal___2SrJ1 .modalBody___25qo_ .formItem___2mxJU input {
  width: 300px;
  height: 34px;
  border-radius: 2px;
  border: 1px solid #e7e7e7;
}
.modal___2SrJ1 .modalBody___25qo_ .formItem___2mxJU div.ant-form-explain___1FbJU {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #d0021b;
  line-height: 17px;
}
.modal___2SrJ1 .modalBody___25qo_ .protocolOuter___261cr {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal___2SrJ1 .modalBody___25qo_ .protocolOuter___261cr .checkbox___3iqVH {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #042044;
  line-height: 20px;
  margin-bottom: 6px;
}
.modal___2SrJ1 .modalBody___25qo_ .protocolOuter___261cr .checkbox___3iqVH a {
  color: #5187D5;
}
.modal___2SrJ1 .modalBody___25qo_ .tipOuter___3ej9X {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal___2SrJ1 .modalBody___25qo_ .tipOuter___3ej9X .tip___2Z_l3 {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #d0021b;
  line-height: 17px;
  margin-bottom: 30px;
}
.modal___2SrJ1 .modalBody___25qo_ .buttonOuter___3fn-J {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal___2SrJ1 .modalBody___25qo_ .buttonOuter___3fn-J .button___1jsEG {
  width: 124px;
  height: 40px;
  background: #5187d5;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin: 0 auto;
}
.modal___2SrJ1 .modalBody___25qo_ .buttonOuter___3fn-J .buttonDisable___vkIDD {
  background: #f6f6f6;
  color: darkgray;
}
