.math-table-box___pRV_q table,
.math-table-box___pRV_q tbody {
  width: 100%;
  height: 130px;
}
.math-table-box___pRV_q .math-table-td___3uf6O {
  border: 1px solid #E5E5E5;
  cursor: pointer;
  padding: 2px;
  width: 16.6%;
}
table .katex .base {
  font-size: 14px !important;
}
table .katex-html {
  text-align: center !important;
}
