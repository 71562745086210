.ant-select-selected-icon {
  display: none !important;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  display: none !important;
}

.ant-select-open .ant-select-arrow-icon svg {
  transform: unset !important;
}
.ant-select-dropdown {
  overflow-y: auto !important;
  max-height: 300px !important;
  background-color: #333333 !important;
  border-radius: 0 !important;
}
@media only screen and (min-width: 1680px) {
  .ant-select-dropdown {
    width: 350px;
  }
}

@media only screen and (max-width: 1679px) and (min-width: 1440px) {
  .ant-select-dropdown {
    width: 300px;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1280px) {
  .ant-select-dropdown {
    width: 300px;
  }
}

@media only screen and (max-width: 1279px) and (min-width: 768px) {
  .ant-select-dropdown {
    width: 300px;
  }
}
.ant-select-dropdown-menu::-webkit-scrollbar {
  height: 5px !important;
}

.ant-select-dropdown-menu::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background: #dfdfdf !important;
  border-radius: 10px !important;
  background-clip: content-box !important;
  border: 7px solid transparent !important;
}
.ant-select-open .ant-select-selection {
  border-bottom: 1px solid #5385d3 !important;
  box-shadow: unset !important;
}
.ant-select-open .ant-select-selection .ant-select-arrow-icon {
  color: #5385d3 !important;
}
/* 选择器下拉菜单字体颜色 */
.ant-select-dropdown-menu,
.ant-select-dropdown-menu-root,
.ant-select-dropdown-menu-vertical li {
  background-color: transparent;
  color: #fff;
}
/* 选择器下拉菜单选中的数据，与鼠标移入样式 */
.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-active {
  transition: color 0.2s !important;
  background-color: #292929 !important;
  color: #5385d3 !important;
}
