.menu-list-area___1CaOZ .menu-item-title___2B-hA {
  color: rgba(142, 142, 160);
  font-size: 12px;
  padding: 12px 12px 8px 12px;
  font-weight: bold;
  display: block;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo {
  display: flex;
  flex-direction: column;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G {
  height: 40px;
  margin-bottom: 2px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G:hover {
  cursor: pointer;
  background-color: rgba(42, 43, 50);
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv {
  width: 160px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #ececf1;
  display: flex;
  align-items: center;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv .talk-icon___3xUA9 {
  font-size: 18px;
  padding-right: 6px;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv .edit-input___3CubA {
  flex: 1;
  height: 20px;
  color: #ececf1;
  background-color: transparent;
  border-color: #fff;
  padding: 0 4px;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-content-area___OlAyv .item-title-text___eYc2e {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ {
  display: none;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K {
  border: none;
  background-color: transparent;
  outline: none;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K .control-icon___1Hsj_ {
  color: #c5c5d2;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G .item-control-area___3wyqJ .control-icon-btn___1Fm9K .control-icon___1Hsj_:hover {
  color: #fff;
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G.is-act-item___3yB13 {
  background-color: rgba(42, 43, 50);
}
.menu-list-area___1CaOZ .history-menu-item-list___k_KBo .history-menu-item___et81G.is-act-item___3yB13 .item-control-area___3wyqJ {
  display: flex;
}
.menu-list-area___1CaOZ .donghua___3_IcJ {
  opacity: 1;
  animation: opacity1___2Tg-A 2s infinite alternate;
}
@keyframes opacity1___2Tg-A {
  0% {
    background: transparent;
  }
  50% {
    background: #2877DC;
  }
  100% {
    background: transparent;
  }
}
