#loginForm .ant-form-item-required {
    font-size: 14px;
}
#loginForm .ant-form-item-label label{
    font-size: 14px;
    color: #042044;
}
#loginForm .ant-input {
    height: 41px;
    font-size: 14px;
}
#loginForm .ant-form-explain {
    margin: 4px 12px;
}
#loginForm .links .register-text {
    color: #6D6D6D;
}
#loginForm .links .login-link {
    color: #002EC2;
}
#loginForm .links{
    padding: 0 4px;
    text-align: right;
    position: absolute;
    top: -41px;
    right: 0;
    color: #001429;
}
.cooperationModal .ant-modal-confirm-body{
    display: flex;
    padding-left: 56px;
}
.cooperationModal .ant-modal-confirm-body img{
    width: 50px;
}
.cooperationModal .ant-modal-confirm-body .ant-modal-confirm-content{
    padding-left: 12px;
    line-height: 25px;
    margin-top: 0px;
    font-size:18px;
    color:rgba(4,32,68,1);
}
.cooperationModal .ant-modal-confirm-btns{
    text-align: center;
    float: none;
    margin-top: 51px;
}
.cooperationModal .ant-btn{
    width:124px;
    height:38px;
    background:rgba(81,135,213,1);
    border-radius:2px;
    font-size:16px;
    color:rgba(255,255,255,1);
    line-height:22px;
    margin-bottom: 37px;
}
