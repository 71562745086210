.lookingForwardToCooperation___25j-G {
  width: 100%;
  height: 262px;
  background-color: #f6f6f6;
  padding-top: 50px;
  text-align: center;
}
.lookingForwardToCooperation___25j-G .title___LMo3V {
  font-size: 20px;
  font-weight: 500;
  color: #042044;
  line-height: 28px;
  margin-bottom: 14px;
  font-family: PingFangSC-Medium, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.lookingForwardToCooperation___25j-G .desc___3RFu9 {
  font-size: 14px;
  font-weight: 400;
  color: #042044;
  line-height: 20px;
  margin-bottom: 39px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
.lookingForwardToCooperation___25j-G .buttonOuter___mR7OR {
  display: flex;
  justify-content: center;
}
.lookingForwardToCooperation___25j-G .buttonOuter___mR7OR .button___2B2m8 {
  width: 140px;
  height: 36px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  font-family: PingFangSC-Regular, PingFang SC, 'Microsoft YaHei', Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  margin-right: 22px;
  border: 0px;
}
.lookingForwardToCooperation___25j-G .buttonOuter___mR7OR .freeTrialBtn___vqFqQ {
  background: #5187d5;
}
.lookingForwardToCooperation___25j-G .buttonOuter___mR7OR .freeTrialBtn___vqFqQ:hover {
  background-color: #417ACC;
  transition: 0.4s;
}
.lookingForwardToCooperation___25j-G .buttonOuter___mR7OR .technDocuBtn___XCEZE {
  background-color: #f6f6f6;
  border: 1px solid #042044;
  color: #042044;
}
.lookingForwardToCooperation___25j-G .buttonOuter___mR7OR .technDocuBtn___XCEZE:hover {
  color: #5187d5;
  border: 1px solid #5187d5;
  transition: 0.4s;
}
