.information_box___1fUSV {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
  margin-bottom: 40px;
}
.information_box___1fUSV h2 {
  font-size: 42px;
  text-align: center;
  line-height: 56px;
  margin-top: 100px;
}
.information_box___1fUSV .content_main___2q4mW {
  line-height: 1.5;
}
