.icdar_box___1AEg2 {
  max-width: 1920px;
  margin: 0 30px;
  min-width: 1200px;
  color: #212931;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
  margin-top: 75px;
}
.icdar_box___1AEg2 .banner___11zvs {
  display: block;
  margin: 100px auto;
  width: 700px;
}
.icdar_box___1AEg2 .content_main___uASRS {
  margin-top: 20px;
}
.icdar_box___1AEg2 b {
  font-weight: bold;
}
.icdar_box___1AEg2 h1 {
  font-size: 46px;
  text-align: center;
  line-height: 56px;
}
.icdar_box___1AEg2 h2 {
  margin: 20px;
  margin-top: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.icdar_box___1AEg2 h3 {
  font-weight: bold;
  font-size: 18px;
}
.icdar_box___1AEg2 .latexClassN___3ihZz .katex___pf7Zb .base___3wVGS {
  font-size: 18px !important;
}
.icdar_box___1AEg2 .tab_box___3GhS0 {
  background: #5187d5;
  margin-bottom: 20px;
}
.icdar_box___1AEg2 .tab_box___3GhS0 ul {
  height: 60px;
  line-height: 60px;
}
.icdar_box___1AEg2 .tab_box___3GhS0 ul li {
  display: inline-block;
  width: 17%;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.icdar_box___1AEg2 .tab_box___3GhS0 ul > li:last-child {
  width: 15%;
}
.icdar_box___1AEg2 .tab_box___3GhS0 ul .isActived___3gzqm {
  background: #1a3a68;
  font-weight: bold;
}
.icdar_box___1AEg2 .change_contents___mmQs4 {
  padding: 20px 80px;
}
.icdar_box___1AEg2 .change_contents___mmQs4 .home_img_1___25b7R {
  width: 700px;
  display: block;
  margin: 20px auto;
}
.icdar_box___1AEg2 .change_contents___mmQs4 .like_a___2HuQ1 {
  cursor: pointer;
  padding: 0 6px;
  color: #303de2;
}
.icdar_box___1AEg2 .change_contents___mmQs4 a {
  color: #303de2;
}
.icdar_box___1AEg2 .change_contents___mmQs4 .home_connect___OamtC img {
  display: inline-block;
  width: 130px;
  height: 130px;
  vertical-align: middle;
}
.icdar_box___1AEg2 .change_contents___mmQs4 .home_connect___OamtC img:nth-of-type(1) {
  width: 410px;
  height: 90px;
}
.icdar_box___1AEg2 .home_connect___OamtC p {
  line-height: 35px;
}
.icdar_box___1AEg2 .home_connect___OamtC b {
  margin-right: 8px;
}
.icdar_box___1AEg2 .home_connect___OamtC .img_box___1awTW {
  margin: 0 auto;
  width: max-content;
}
.icdar_box___1AEg2 .home_connect___OamtC .img_box___1awTW img {
  margin: 40px;
}
.icdar_box___1AEg2 .body_text___pGqo6 {
  line-height: 35px;
  margin-bottom: 10px;
}
.icdar_box___1AEg2 .span_bg___3dx6F {
  color: #fff;
  background: #666666;
  padding: 0 3px;
  border-radius: 3px;
}
.icdar_box___1AEg2 table {
  margin: 20px auto;
  width: 1000px;
}
.icdar_box___1AEg2 table thead {
  background: gray;
  color: #fff;
  font-weight: 600;
}
.icdar_box___1AEg2 table td {
  padding: 20px 30px;
  text-align: center;
  vertical-align: middle;
}
.icdar_box___1AEg2 table tbody tr {
  border-bottom: solid 1px;
  border-left: 0;
  border-right: 0;
  border-color: #eeeeee;
}
.icdar_box___1AEg2 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(220, 220, 220, 0.25);
}
.icdar_box___1AEg2 .cycle_item___2efK6 {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: black;
  margin-right: 5px;
  vertical-align: middle;
}
