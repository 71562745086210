#functionBox .ant-collapse-header{
    padding: 19px 0 20px 22px;
    background:rgba(240,245,252,1);
    font-size:16px;
    font-weight:400;
    color:rgba(4,32,68,1);
    line-height:22px;
}
#functionBox .ant-collapse-item-active .ant-collapse-header{
    background:rgba(188,216,255,1);
}
#functionBox .ant-collapse-content{
    height:145px;
    background:rgba(252,252,252,1);
    border-radius:3px;
    border:1px solid rgba(233,233,233,1);
    margin-top: 10px;
    overflow: scroll;
}
#functionBox .ant-collapse-content .ant-collapse-content-box{
    padding: 14px 18px;
}
#functionBox .ant-collapse-header .ant-collapse-extra {
    float: left;
}
#functionBox .ant-collapse-header img{
    width: 15px;
    height: 20px;
    margin-right: 10px;
    vertical-align: text-bottom;
}
#functionBox .ant-collapse-header .ant-progress-line {
    position: absolute;
    width: 100%;
    font-size: 14px;
    bottom: -8px;
    left: 0;
}
#functionBox .ant-collapse-header .ant-progress-line .ant-progress-inner{
    background-color: transparent;
}
