.gpt_box___1hl_H {
  font-family: 'S\F6hne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji';
  height: 100vh;
  overflow: hidden;
}
.gpt_box___1hl_H .sider_box___3x4Dc {
  background-color: #202123;
  height: 100vh;
  color: #fff;
  padding: 0 7px;
  flex: 0 0 260px !important;
  max-width: 260px !important;
  min-width: 260px !important;
  width: 260px;
}
.gpt_box___1hl_H .sider_box___3x4Dc .left-menu-area___2J5t0 {
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.gpt_box___1hl_H .sider_box___3x4Dc ::-webkit-scrollbar {
  width: 2px;
}
.gpt_box___1hl_H .sider_box___3x4Dc ::-webkit-scrollbar-thumb {
  background-color: #838395;
}
.gpt_box___1hl_H .sider_box___3x4Dc .add_chat___1nwTW {
  margin-top: 8px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  padding: 13px 12px;
  cursor: pointer;
  border: 1px solid;
  border-color: hsla(0, 0%, 100%, 0.2);
  border-radius: 6px;
}
.gpt_box___1hl_H .sider_box___3x4Dc .add_chat___1nwTW .top-control-area___3stvy {
  align-items: center;
  display: flex;
}
.gpt_box___1hl_H .sider_box___3x4Dc .add_chat___1nwTW .top-control-area___3stvy .add-chat-icon___3fz16 {
  font-size: 14px;
}
.gpt_box___1hl_H .sider_box___3x4Dc .add_chat___1nwTW .top-control-area___3stvy .add-chat-text___3Crw2 {
  padding-left: 10px;
  font-size: 12px;
}
.gpt_box___1hl_H .sider_box___3x4Dc .add_chat___1nwTW:hover {
  background-color: rgba(42, 43, 50);
}
.gpt_box___1hl_H .sider_box___3x4Dc .sider_list___1fzeo {
  flex: 1;
}
.gpt_box___1hl_H .sider_box___3x4Dc .footer-afea___9KjSK {
  height: 60px;
}
.gpt_box___1hl_H .sider_box___3x4Dc .footer-afea___9KjSK .user-info___wlyfj {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  cursor: pointer;
}
.gpt_box___1hl_H .sider_box___3x4Dc .footer-afea___9KjSK .user-info___wlyfj:hover {
  background-color: rgba(42, 43, 50);
}
.gpt_box___1hl_H .sider_box___3x4Dc .footer-afea___9KjSK .user-info___wlyfj .user-icon___3Y0m_ {
  font-size: 16px;
  color: #d9d9e3;
}
.gpt_box___1hl_H .sider_box___3x4Dc .footer-afea___9KjSK .user-info___wlyfj .user-nikename___1ev9o {
  color: #fff;
  padding-left: 8px;
  font-size: 14px;
}
.gpt_box___1hl_H .tal-gpt-box___1_cKU {
  background: #fff;
}
.gpt_box___1hl_H .tal-gpt-box___1_cKU .tal-title___1NIXu {
  font-size: 35px;
  text-align: center;
  padding-top: 60px;
  margin-bottom: 60px;
  color: #2c3e50;
}
.gpt_box___1hl_H .tal-gpt-box___1_cKU .tal-content___Y5cGR {
  width: 855px;
  margin: 0 auto;
  padding: 15px 0;
  overflow: hidden;
}
.gpt_box___1hl_H .tal-gpt-box___1_cKU .tal-content___Y5cGR .tal-tab-title___2b3p9 .tal-tab-title-item___2Gb-R {
  display: inline-block;
  width: 33%;
}
.gpt_box___1hl_H .tal-gpt-box___1_cKU .tal-content___Y5cGR .tal-tab-title___2b3p9 .tal-tab-title-item___2Gb-R img {
  display: block;
  margin: 0 auto;
}
.gpt_box___1hl_H .tal-gpt-box___1_cKU .tal-content___Y5cGR .tal-tab-title___2b3p9 .tal-tab-title-item___2Gb-R p {
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: 700;
  color: #2c3e50;
  margin: 0;
  margin-top: 10px;
}
.gpt_box___1hl_H .tal-gpt-box___1_cKU .tal-ques-content___1uEbl {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
}
.gpt_box___1hl_H .tal-gpt-box___1_cKU .tal-ques-content___1uEbl .tal-ques-colum-item___2mIYp {
  display: table;
  text-align: center;
  width: 270px;
  min-height: 62px;
  margin: 0 auto;
  padding: 10px 10px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #006AD3, #71abe3);
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
}
.gpt_box___1hl_H .tal-gpt-box___1_cKU .tal-ques-content___1uEbl .tal-ques-colum-item___2mIYp span {
  display: table-cell;
  vertical-align: middle;
}
.gpt_box___1hl_H #sidebarBox {
  display: none;
}
.gpt_box___1hl_H textarea {
  resize: none;
}
.gpt_box___1hl_H .ques-input___2TyWk:focus {
  outline: none;
}
