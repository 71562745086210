.placeholder___3cSWh {
  height: 75px;
  background: #1F1F1F;
}
.cooperationBox___1Bbc6 {
  background: #F6F6F6;
  padding: 30px 0;
}
.cooperationBox___1Bbc6 .cooperationContent___w7ucf {
  background: #fff;
  padding-bottom: 36px;
}
.cooperationBox___1Bbc6 .cooperationContent___w7ucf h2 {
  font-size: 24px;
  color: #042044;
  line-height: 33px;
  padding: 20px 0 15px;
  text-align: center;
}
.cooperationBox___1Bbc6 .cooperationContent___w7ucf .emailBox___3YP8m {
  display: flex;
  justify-content: center;
}
.cooperationBox___1Bbc6 .cooperationContent___w7ucf .emailBox___3YP8m .email___1BhP7 {
  font-size: 14px;
  color: #5187d5;
  line-height: 20px;
  padding-left: 8px;
}
.cooperationBox___1Bbc6 .cooperationContent___w7ucf .tips___1TAap {
  font-size: 14px;
  color: #959595;
  line-height: 21px;
  margin: 24px 0;
  text-align: center;
}
.cooperationBox___1Bbc6 .cooperationContent___w7ucf .loginForm___2bfG1 {
  width: 650px;
  margin: 0 auto;
}
.cooperationBox___1Bbc6 .cooperationContent___w7ucf .loginForm___2bfG1 .getSmsCodeBtn___2l4ql {
  height: 40px;
  border: 1px solid #002EC2;
  color: #002EC2;
}
.cooperationBox___1Bbc6 .cooperationContent___w7ucf .loginForm___2bfG1 .submitButton___r90GY {
  width: 126px;
  height: 38px;
  background: #5187d5;
  border-radius: 2px;
  text-align: center;
  line-height: 38px;
  font-size: 16px;
  display: block;
  border: none;
  margin: 16px auto 0;
}
