.right-gpt-box___2tkMQ {
  position: relative;
  background: #fff;
  height: 100vh;
  overflow: hidden;
  font-family: 'PingFang SC', 'Microsoft YaHei';
}
.right-gpt-box___2tkMQ .ask-list-box___uzYJ_ {
  height: calc(100vh - 115px);
  overflow-x: hidden;
}
.right-gpt-box___2tkMQ ::-webkit-scrollbar {
  width: 5px;
}
.right-gpt-box___2tkMQ ::-webkit-scrollbar-thumb {
  background-color: #6c6c7e;
}
.right-gpt-box___2tkMQ textarea {
  resize: none;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy {
  min-height: 30px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  z-index: 100;
  bottom: 25px;
  background: #fff;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .math-table-ref-box___2puSt {
  position: relative;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .math-table-ref-box___2puSt .close-image___3cEMU {
  position: absolute;
  z-index: 100000;
  right: 5px;
  top: 15px;
  cursor: pointer;
  opacity: 0.5;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .math-table-ref-box___2puSt .close-image___3cEMU:hover {
  opacity: 1;
  color: #006ad3;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .picture-preview___1GT2b {
  position: relative;
  margin-bottom: 20px;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .picture-preview___1GT2b .preview-title___1L4ld {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 20px;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .picture-preview___1GT2b .picture-ctn___149GW {
  position: relative;
  width: 60%;
  box-shadow: 0 0 10px #ccc;
  margin: 0 auto;
  border-radius: 10px;
  text-align: center;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .picture-preview___1GT2b .picture-ctn___149GW img {
  max-width: 100%;
  border-radius: 10px;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .picture-preview___1GT2b .picture-ctn___149GW .picture-ctn-inner___tx_yl {
  padding: 15px;
  min-height: 100px;
  max-height: 200px;
  overflow: auto;
  position: relative;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .picture-preview___1GT2b .picture-ctn___149GW .picture-ctn-inner___tx_yl .ant-spin-spinning {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .picture-preview___1GT2b .picture-ctn___149GW .picture-ctn-inner___tx_yl .ant-spin-text {
  font-size: 12px;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .picture-preview___1GT2b .picture-ctn___149GW .picture-ctn-inner___tx_yl ::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .picture-preview___1GT2b .picture-ctn___149GW .close-image___3cEMU {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  opacity: 0.5;
  z-index: 999;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .picture-preview___1GT2b .picture-ctn___149GW .close-image___3cEMU:hover {
  opacity: 1;
  color: #006ad3;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .latex-preview___3TQoK {
  position: relative;
  margin-bottom: 20px;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .latex-preview___3TQoK .preview-title___1L4ld {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 20px;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .latex-preview___3TQoK .picture-ctn___149GW {
  position: relative;
  width: 60%;
  box-shadow: 0 0 10px #ccc;
  margin: 0 auto;
  border-radius: 10px;
  text-align: center;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .latex-preview___3TQoK .picture-ctn___149GW .picture-ctn-inner___tx_yl {
  padding: 20px 15px;
  max-height: 200px;
  overflow: auto;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .latex-preview___3TQoK .picture-ctn___149GW .picture-ctn-inner___tx_yl ::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .latex-preview___3TQoK .picture-ctn___149GW .close-image___3cEMU {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  opacity: 0.5;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .latex-preview___3TQoK .picture-ctn___149GW .close-image___3cEMU:hover {
  opacity: 1;
  color: #006ad3;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .input-ctn___1vIXs {
  position: relative;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .input-box-bottom-inner___18p_b {
  width: 60%;
  box-shadow: 0 0 10px #ccc;
  margin-left: 20%;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .math-table-ref-box___2puSt {
  width: 60%;
  margin-left: 20%;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .send-icon___2hKM2,
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .send-icon-no___3cSkB {
  font-size: 30px;
  vertical-align: middle;
  margin-left: 20px;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .send-icon-no___3cSkB {
  cursor: not-allowed !important;
  opacity: 0.1 !important;
}
.right-gpt-box___2tkMQ .input-box-bottom___1cPYy .bottom-tools-div___2LG0k {
  position: absolute;
  top: 12px;
  left: 20px;
}
.right-gpt-box___2tkMQ .ques-input___3bov7 {
  display: inline-block;
  width: calc(100% - 80px);
  padding: 20px 15px;
  background-color: transparent;
  border-color: transparent;
  outline: none;
  vertical-align: middle;
  max-height: 200px !important;
  resize: none;
}
.right-gpt-box___2tkMQ .ques-input___3bov7:focus {
  outline: none;
}
.right-gpt-box___2tkMQ .control-icon-btn___1ZRpW {
  border: none;
  background-color: transparent;
  outline: none;
}
.right-gpt-box___2tkMQ .control-icon-btn___1ZRpW .control-icon___pzem_ {
  color: #c5c5d2;
}
.right-gpt-box___2tkMQ .control-icon-btn___1ZRpW .control-icon___pzem_:hover {
  color: #fff;
}
.right-gpt-box___2tkMQ .control-icon-btn___1ZRpW .diancai-icon___2539u {
  transform: rotate(180deg);
}
.right-gpt-box___2tkMQ .control-icon-btn___1ZRpW .icon-shan-dian___3rNoP {
  font-size: 16px;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .usr-icon___90-iR,
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .usr-icon-gpt___D8ots {
  width: 28px;
  height: 28px;
  background: #006bd6;
  text-align: center;
  margin-right: 28px;
  color: #fff;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .usr-icon___90-iR img,
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .usr-icon-gpt___D8ots img {
  width: 100%;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .usr-icon-gpt___D8ots {
  background: transparent;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ques___3dwer,
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ans___1NShw {
  background: #fff;
  padding: 28px 20%;
  min-height: 80px;
  display: flex;
  box-sizing: border-box;
  line-height: 28px;
  font-size: 16px;
  word-break: break-all;
  text-align: justify;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ques___3dwer .ask-inner___Bzw4A,
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ans___1NShw .ask-inner___Bzw4A {
  white-space: pre-wrap;
  flex: 1;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ques___3dwer .ask-inner___Bzw4A em,
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ans___1NShw .ask-inner___Bzw4A em {
  display: inline-block;
  width: 30px;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ques___3dwer .ask-inner___Bzw4A .katex-display___3HeCC > .katex___37qhA,
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ans___1NShw .ask-inner___Bzw4A .katex-display___3HeCC > .katex___37qhA {
  white-space: normal !important;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ques___3dwer .ask-inner___Bzw4A .katex-display___3HeCC > .katex___37qhA .base___9a-ky,
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ans___1NShw .ask-inner___Bzw4A .katex-display___3HeCC > .katex___37qhA .base___9a-ky {
  white-space: normal;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ques___3dwer .btn-box___1Y8LE,
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ans___1NShw .btn-box___1Y8LE {
  margin-left: 20px;
  width: 90px;
  display: inline-block;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ans___1NShw {
  background: #f7f7f8;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ans___1NShw .btn-box___1Y8LE .control-icon___pzem_ {
  color: gray;
  font-size: 18px;
}
.right-gpt-box___2tkMQ .dialog-box___3yU0_ .ask-ans___1NShw .btn-box___1Y8LE .control-icon___pzem_:hover {
  color: #006ad5;
}
.right-gpt-box___2tkMQ .ant-input:focus,
.right-gpt-box___2tkMQ .ant-input-focused {
  outline: none;
  box-shadow: 0 0;
  -webkit-box-shadow: 0 0;
}
.right-gpt-box___2tkMQ ant-input {
  resize: none;
}
.right-gpt-box___2tkMQ .katex-display {
  font-family: 'PingFang SC', 'Microsoft YaHei';
  margin: 0;
}
.right-gpt-box___2tkMQ .katex-display > .katex {
  white-space: normal !important;
}
.right-gpt-box___2tkMQ .katex-display > .katex .mathdefault {
  font-style: normal;
  font-family: auto;
}
.right-gpt-box___2tkMQ .katex-display > .katex .katex-html {
  text-align: left;
}
.right-gpt-box___2tkMQ .katex-display > .katex .base {
  white-space: normal;
  width: auto;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.65);
  font-family: 'PingFang SC', 'Microsoft YaHei';
}
.right-gpt-box___2tkMQ .model-ques-input___2zjW7 {
  resize: none;
}
.tal-gpt-box___wOqc4 {
  background: #fff;
}
.tal-gpt-box___wOqc4 .tal-title___3PNPE {
  font-size: 35px;
  text-align: center;
  padding-top: 60px;
  margin-bottom: 50px;
  color: #2c3e50;
}
.tal-gpt-box___wOqc4 .tal-content___2SaKo {
  width: 855px;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}
.tal-gpt-box___wOqc4 .tal-content___2SaKo .tal-tab-title___2yc0_ .tal-tab-title-item___cvovC {
  display: inline-block;
  width: 33%;
}
.tal-gpt-box___wOqc4 .tal-content___2SaKo .tal-tab-title___2yc0_ .tal-tab-title-item___cvovC img {
  display: block;
  margin: 0 auto;
}
.tal-gpt-box___wOqc4 .tal-content___2SaKo .tal-tab-title___2yc0_ .tal-tab-title-item___cvovC p {
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: 700;
  color: #2c3e50;
  margin: 0;
  margin-top: 10px;
}
.tal-gpt-box___wOqc4 .opacity___3Taqi {
  opacity: 0.6;
}
.tal-gpt-box___wOqc4 .tal-ques-content___3ToAs {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}
.tal-gpt-box___wOqc4 .tal-ques-content___3ToAs .tal-ques-colum-item___3ZzVA {
  display: table;
  text-align: center;
  width: 270px;
  min-height: 62px;
  margin: 0 auto;
  padding: 10px 10px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #006ad3, #71abe3);
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tal-gpt-box___wOqc4 .tal-ques-content___3ToAs .tal-ques-colum-item___3ZzVA span {
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 23px;
}
.recording___3hweU {
  position: relative;
  z-index: 10000;
  display: inline-block;
}
.hidden___3AlXR {
  display: none;
}
.yuyin-icon-start___26tnK {
  font-size: 26px;
}
.model-tip___1vpBu {
  position: absolute;
  top: 20px;
  right: 20px;
}
