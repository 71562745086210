.Center___3j04- {
  width: 100%;
  min-width: 1200px;
}
.Center___3j04- .download_tabs___3F496 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 129px;
  align-items: center;
}
.Center___3j04- .download_tabs___3F496 .ant-tabs-bar {
  margin: 0 0 0 0;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
}
.appBg___2Okl9 {
  max-width: 1920px;
  min-width: 1200px;
}
.appBg___2Okl9 .honglian___1urop {
  width: 1200px;
  margin: 0 auto;
}
.appBg___2Okl9 .honglian___1urop .honglian_1eft___3rHVp {
  width: 600px;
  position: absolute;
}
.appBg___2Okl9 .honglian___1urop .honglian_1eft___3rHVp .red_hong___32qDg .logo___2BNCF {
  width: 88px;
  padding-top: 150px;
}
.appBg___2Okl9 .honglian___1urop .honglian_1eft___3rHVp .red_hong___32qDg .te___18aL1 {
  width: 144px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
  padding-top: 28px;
}
.appBg___2Okl9 .honglian___1urop .honglian_1eft___3rHVp .red_hong___32qDg .test___28n7q {
  width: 400px;
  height: 48px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  margin-top: 12px;
}
.appBg___2Okl9 .honglian___1urop .honglian_1eft___3rHVp .red_hong___32qDg .hong_download___3gwpc {
  width: 215px;
  height: 56px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  margin-top: 40px;
  cursor: pointer;
}
.appBg___2Okl9 .honglian___1urop .honglian_1eft___3rHVp .red_hong___32qDg .hong_download___3gwpc .download___8X9Z9 .hong_window___1kOXj {
  margin-top: 11px;
  width: 32px;
  height: 32px;
  margin-left: 16px;
}
.appBg___2Okl9 .honglian___1urop .honglian_1eft___3rHVp .red_hong___32qDg .hong_download___3gwpc .download___8X9Z9 .hong_letter___12DJK {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  height: 28px;
  margin-top: -27px;
  margin-left: 60px;
}
.appBg___2Okl9 .honglian___1urop .honglian_1eft___3rHVp .red_hong___32qDg .hong_download_black___3z8pn {
  width: 215px;
  height: 56px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #5187D5;
  margin-top: 40px;
  cursor: pointer;
}
.appBg___2Okl9 .honglian___1urop .honglian_1eft___3rHVp .red_hong___32qDg .hong_download_black___3z8pn .download_black___2kXaw .hong_window_black___34q7s {
  margin-top: 11px;
  width: 32px;
  height: 32px;
  margin-left: 16px;
}
.appBg___2Okl9 .honglian___1urop .honglian_1eft___3rHVp .red_hong___32qDg .hong_download_black___3z8pn .download_black___2kXaw .hong_letter_black___3k7_c {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  height: 28px;
  margin-top: -27px;
  margin-left: 60px;
  color: #5187D5;
}
.appBg___2Okl9 .honglian___1urop .honglian_right___3FmX7 {
  width: 600px;
  margin-left: 600px;
  height: 640px;
}
.appBg___2Okl9 .honglian___1urop .honglian_right___3FmX7 .honglian_logo___1uMP- {
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}
.appBg___2Okl9 .practicebg___1JHi4 {
  max-width: 1920px;
  min-width: 1200px;
  background: #f6f6f6;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- {
  margin: 0 auto;
  width: 1200px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF {
  width: 600px;
  position: absolute;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .ai___oPPmR {
  padding-top: 122px;
  width: 88px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .practice_letter___3RumI {
  width: 144px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
  padding-top: 28px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .practice_word___1K9ap {
  width: 400px;
  height: 48px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  margin-top: 12px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .ios___3a2zi {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  margin-top: 40px;
  position: absolute;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .ios___3a2zi .practice_ios___1o0Ld {
  width: 60px;
  height: 60px;
  margin: 18px 22px 22px 18px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .ios___3a2zi .practice_ios_picture___275S2 {
  width: 90px;
  height: 90px;
  margin: 3.5px 3.3px 6.5px 3.3px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .ios___3a2zi .practice_ios_letter___xkxcC {
  margin-top: 12px;
  text-align: center;
  width: 100px;
  height: 32px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .idrod___24Ujy {
  margin-left: 128px;
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  margin-top: 40px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .idrod___24Ujy .practice_idrod___3kvQV {
  width: 60px;
  height: 60px;
  margin: 18px 22px 22px 18px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .idrod___24Ujy .practice_idrod_piture___1XxF4 {
  width: 90px;
  height: 90px;
  margin: 3px 3.3px 7px 3.3px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_left___32vsF .idrod___24Ujy .letter_idrod___1bYnt {
  margin-top: 12px;
  text-align: center;
  width: 100px;
  height: 32px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_right___3pN8- {
  width: 600px;
  margin-left: 600px;
  height: 640px;
  display: flex;
  justify-content: space-evenly;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_right___3pN8- .airace_left___3EKlH {
  width: 222px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.appBg___2Okl9 .practicebg___1JHi4 .practice___3VYT- .practice_right___3pN8- .airace_right___1kU9t {
  width: 222px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.appBg___2Okl9 .soBg___19f-h {
  max-width: 1920px;
  min-width: 1200px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB {
  width: 1200px;
  margin: 0 auto;
  border: 1px solid #ffffff;
  height: 640px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be {
  width: 600px;
  position: absolute;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_img___2nnxn {
  padding-top: 120px;
  width: 88px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_letter___2lX5R {
  width: 144px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
  padding-top: 28px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_letter_bottom___1IoaT {
  width: 400px;
  height: 48px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  margin-top: 12px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_ios___1YiyV {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  margin-top: 40px;
  position: absolute;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_ios___1YiyV .so_ios_picture___158Hb {
  width: 60px;
  height: 60px;
  margin: 18px 22px 22px 18px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_ios___1YiyV .so_ios_scan___3thlb {
  width: 90px;
  height: 90px;
  margin: 3.5px 3.3px 6.5px 3.3px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_ios___1YiyV .so_ios_letter___kAULI {
  margin-top: 12px;
  text-align: center;
  width: 100px;
  height: 32px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_idrod___FtwoX {
  margin-left: 128px;
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  margin-top: 40px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_idrod___FtwoX .so_idrod_picture___vJ76_ {
  width: 60px;
  height: 60px;
  margin: 18px 22px 22px 18px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_idrod___FtwoX .so_idrod_scan___p75CC {
  width: 90px;
  height: 90px;
  margin: 3px 3.3px 7px 3.3px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_left___2c1be .so_idrod___FtwoX .so_idrod_letter___2ZpqM {
  margin-top: 12px;
  text-align: center;
  width: 100px;
  height: 32px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_right___26sPg {
  width: 600px;
  margin-left: 600px;
  height: 640px;
}
.appBg___2Okl9 .soBg___19f-h .so___1ZpTB .so_right___26sPg .so_photo___1IQlb {
  width: 592px;
  display: flex;
  margin-top: 181px;
  justify-content: center;
  border-radius: 4px;
}
.appBg___2Okl9 .dayuBg___2sD9l {
  max-width: 1920px;
  min-width: 1200px;
  background: #f6f6f6;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR {
  width: 1200px;
  margin: 0 auto;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf {
  width: 600px;
  position: absolute;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_correct___3SToX {
  padding-top: 156px;
  width: 88px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_letter___1BIfH {
  width: 144px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
  padding-top: 40px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_letter_bottom___1mrAg {
  width: 400px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  margin-top: 12px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_ios___2zJTf {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  margin-top: 40px;
  position: absolute;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_ios___2zJTf .dayu_ios_picture___1Le-U {
  width: 60px;
  height: 60px;
  margin: 18px 22px 22px 18px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_ios___2zJTf .dayu_ios_scan___1v7AN {
  width: 90px;
  height: 90px;
  margin: 3.5px 3.3px 6.5px 3.3px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_ios___2zJTf .dayu_ios_letter___y3vEP {
  margin-top: 12px;
  text-align: center;
  width: 100px;
  height: 32px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_idrod___1fTsT {
  margin-left: 128px;
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  margin-top: 40px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_idrod___1fTsT .dayu_idrod_picture___HP9XS {
  width: 60px;
  height: 60px;
  margin: 18px 22px 22px 18px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_idrod___1fTsT .dayu_idrod_scan___1M6Cd {
  width: 90px;
  height: 90px;
  margin: 3px 3.3px 7px 3.3px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_left___25BCf .dayu_idrod___1fTsT .dayu_idrod_letter___2cTyP {
  margin-top: 12px;
  text-align: center;
  width: 100px;
  height: 32px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_right___3DW8I {
  width: 600px;
  margin-left: 600px;
  height: 640px;
  display: flex;
  justify-content: space-evenly;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_right___3DW8I .dayu_picture1___1N56- {
  width: 222px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.appBg___2Okl9 .dayuBg___2sD9l .dayu___w7AGR .dayu_right___3DW8I .dayu_picture2___SiVIt {
  width: 222px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.appBg___2Okl9 .viewBg___2JQqE {
  max-width: 1920px;
  min-width: 1200px;
  border: 1px solid #ffffff;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO {
  margin: 0 auto;
  width: 1200px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl {
  width: 600px;
  position: absolute;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_correct___e2BcO {
  padding-top: 102px;
  width: 88px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_letter___1m9bs {
  width: 144px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
  padding-top: 40px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_letter_bottom___1tBv6 {
  width: 400px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  margin-top: 12px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_ios___2t1X9 {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  margin-top: 40px;
  position: absolute;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_ios___2t1X9 .view_ios_picture___1t3AI {
  width: 60px;
  height: 60px;
  margin: 18px 22px 22px 18px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_ios___2t1X9 .record_ios_scan___BOZtF {
  width: 90px;
  height: 90px;
  margin: 3.5px 3.3px 6.5px 3.3px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_ios___2t1X9 .view_ios_letter___39LKi {
  margin-top: 12px;
  text-align: center;
  width: 100px;
  height: 32px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_idrod___2Qq7e {
  margin-left: 128px;
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  margin-top: 40px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_idrod___2Qq7e .view_idrod_picture___172Sn {
  width: 60px;
  height: 60px;
  margin: 18px 22px 22px 18px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_idrod___2Qq7e .record_idrod_scan___cOQFO {
  width: 90px;
  height: 90px;
  margin: 3px 3.3px 7px 3.3px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_left___2z6Vl .view_idrod___2Qq7e .view_idrod_letter___3v6d8 {
  margin-top: 12px;
  text-align: center;
  width: 100px;
  height: 32px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_right___3TpbR {
  width: 600px;
  margin-left: 600px;
  height: 640px;
  display: flex;
  justify-content: space-evenly;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_right___3TpbR .view_picture_one___3foeA {
  width: 222px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.appBg___2Okl9 .viewBg___2JQqE .view___3xgZO .view_right___3TpbR .view_picture_two___3jhNC {
  width: 222px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.sdkBg___1ZNGz {
  max-width: 1920px;
  min-width: 1200px;
}
.sdkBg___1ZNGz .chinese___2aYzz {
  height: 640px;
}
.sdkBg___1ZNGz .chinese___2aYzz .chinese_letter___1gaUl {
  padding-top: 80px;
  text-align: center;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.sdkBg___1ZNGz .chinese___2aYzz .chinese_letter_bottom___1ruW5 {
  text-align: center;
  padding-top: 28px;
  line-height: 20px;
}
.sdkBg___1ZNGz .chinese___2aYzz .chinese_logos___teXRx {
  margin-top: 48px;
  min-width: calc((100% - 20px) / 4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sdkBg___1ZNGz .chinese___2aYzz .chinese_logos___teXRx .chinese_logos_one___1PO7C {
  margin-right: 20px;
  width: 284px;
  height: 320px;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
}
.sdkBg___1ZNGz .chinese___2aYzz .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_window___3y2QR {
  width: 100px;
  height: 96px;
  display: block;
  margin: 0 auto;
  margin-top: 47px;
}
.sdkBg___1ZNGz .chinese___2aYzz .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_letter___1gaUl {
  text-align: center;
  padding-top: 24px;
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.sdkBg___1ZNGz .chinese___2aYzz .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 {
  display: flex;
  margin-top: 24px;
}
.sdkBg___1ZNGz .chinese___2aYzz .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 .chinese_button_1etter___Ocy1q {
  margin: 0 auto;
  width: 140px;
  height: 50px;
  background-color: #5187d5;
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  line-height: 45px;
  border: 0px;
  border-radius: 4px;
}
.sdkBg___1ZNGz .chinese___2aYzz .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 .chinese_button_1etter___Ocy1q:hover {
  background-color: #6c9adb;
}
.sdkBg___1ZNGz .gesture___HBgHB {
  height: 640px;
  background-color: #f6f6f6;
}
.sdkBg___1ZNGz .gesture___HBgHB .chinese_letter___1gaUl {
  padding-top: 80px;
  text-align: center;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.sdkBg___1ZNGz .gesture___HBgHB .chinese_letter_bottom___1ruW5 {
  text-align: center;
  padding-top: 28px;
  line-height: 20px;
}
.sdkBg___1ZNGz .gesture___HBgHB .chinese_logos___teXRx {
  margin-top: 48px;
  display: flex;
  min-width: calc((100% - 20px) / 3);
  justify-content: center;
  align-items: center;
}
.sdkBg___1ZNGz .gesture___HBgHB .chinese_logos___teXRx .chinese_logos_one___1PO7C {
  margin-right: 20px;
  width: 284px;
  height: 320px;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  background-color: #ffffff;
}
.sdkBg___1ZNGz .gesture___HBgHB .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_window___3y2QR {
  width: 100px;
  height: 96px;
  display: block;
  margin: 0 auto;
  margin-top: 47px;
}
.sdkBg___1ZNGz .gesture___HBgHB .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_letter___1gaUl {
  text-align: center;
  padding-top: 24px;
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.sdkBg___1ZNGz .gesture___HBgHB .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 {
  display: flex;
  margin-top: 24px;
}
.sdkBg___1ZNGz .gesture___HBgHB .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 .chinese_button_1etter___Ocy1q {
  margin: 0 auto;
  width: 140px;
  height: 50px;
  background-color: #5187d5;
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  line-height: 45px;
  border: 0px;
  border-radius: 4px;
}
.sdkBg___1ZNGz .gesture___HBgHB .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 .chinese_button_1etter___Ocy1q:hover {
  background-color: #6c9adb;
}
.sdkBg___1ZNGz .face___2R0Li {
  height: 640px;
}
.sdkBg___1ZNGz .face___2R0Li .chinese_letter___1gaUl {
  padding-top: 80px;
  text-align: center;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.sdkBg___1ZNGz .face___2R0Li .chinese_letter_bottom___1ruW5 {
  text-align: center;
  padding-top: 28px;
  line-height: 20px;
}
.sdkBg___1ZNGz .face___2R0Li .chinese_logos___teXRx {
  margin-top: 48px;
  display: flex;
  min-width: calc((100% - 20px) / 2);
  justify-content: center;
  align-items: center;
}
.sdkBg___1ZNGz .face___2R0Li .chinese_logos___teXRx .chinese_logos_one___1PO7C {
  margin-right: 20px;
  width: 284px;
  height: 320px;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
}
.sdkBg___1ZNGz .face___2R0Li .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_window___3y2QR {
  width: 100px;
  height: 96px;
  display: block;
  margin: 0 auto;
  margin-top: 47px;
}
.sdkBg___1ZNGz .face___2R0Li .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_letter___1gaUl {
  text-align: center;
  padding-top: 24px;
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.sdkBg___1ZNGz .face___2R0Li .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 {
  display: flex;
  margin-top: 24px;
}
.sdkBg___1ZNGz .face___2R0Li .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 .chinese_button_1etter___Ocy1q {
  margin: 0 auto;
  width: 140px;
  height: 50px;
  background-color: #5187d5;
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  line-height: 45px;
  border: 0px;
  border-radius: 4px;
}
.sdkBg___1ZNGz .face___2R0Li .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 .chinese_button_1etter___Ocy1q:hover {
  background-color: #6c9adb;
}
.sdkBg___1ZNGz .red_lotus___1crGc {
  width: 100%;
  background-color: #f6f6f6;
  height: 640px;
}
.sdkBg___1ZNGz .red_lotus___1crGc .chinese_letter___1gaUl {
  padding-top: 80px;
  text-align: center;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.sdkBg___1ZNGz .red_lotus___1crGc .chinese_letter_bottom___1ruW5 {
  text-align: center;
  padding-top: 28px;
  line-height: 20px;
}
.sdkBg___1ZNGz .red_lotus___1crGc .chinese_logos___teXRx {
  margin-top: 48px;
  display: flex;
  min-width: calc((100% - 20px) / 3);
  justify-content: center;
  align-items: center;
}
.sdkBg___1ZNGz .red_lotus___1crGc .chinese_logos___teXRx .chinese_logos_one___1PO7C {
  margin-right: 20px;
  width: 284px;
  height: 320px;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
  background-color: #ffffff;
}
.sdkBg___1ZNGz .red_lotus___1crGc .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_window___3y2QR {
  width: 100px;
  height: 96px;
  display: block;
  margin: 0 auto;
  margin-top: 47px;
}
.sdkBg___1ZNGz .red_lotus___1crGc .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_letter___1gaUl {
  text-align: center;
  padding-top: 24px;
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.sdkBg___1ZNGz .red_lotus___1crGc .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 {
  display: flex;
  margin-top: 24px;
}
.sdkBg___1ZNGz .red_lotus___1crGc .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 .chinese_button_1etter___Ocy1q {
  margin: 0 auto;
  width: 140px;
  height: 50px;
  background-color: #5187d5;
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  line-height: 45px;
  border: 0px;
  border-radius: 4px;
}
.sdkBg___1ZNGz .red_lotus___1crGc .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 .chinese_button_1etter___Ocy1q:hover {
  background-color: #6c9adb;
}
.sdkBg___1ZNGz .main___bQTi0 {
  width: 100%;
  height: 640px;
}
.sdkBg___1ZNGz .main___bQTi0 .chinese_letter___1gaUl {
  padding-top: 80px;
  text-align: center;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.sdkBg___1ZNGz .main___bQTi0 .chinese_letter_bottom___1ruW5 {
  text-align: center;
  padding-top: 28px;
  line-height: 20px;
}
.sdkBg___1ZNGz .main___bQTi0 .chinese_logos___teXRx {
  margin-top: 48px;
  display: flex;
  min-width: calc((100% - 20px) / 3);
  justify-content: center;
  align-items: center;
}
.sdkBg___1ZNGz .main___bQTi0 .chinese_logos___teXRx .chinese_logos_one___1PO7C {
  margin-right: 20px;
  width: 284px;
  height: 320px;
  border-radius: 8px;
  border: 2px solid #e6e6e6;
}
.sdkBg___1ZNGz .main___bQTi0 .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_window___3y2QR {
  width: 100px;
  height: 96px;
  display: block;
  margin: 0 auto;
  margin-top: 47px;
}
.sdkBg___1ZNGz .main___bQTi0 .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_letter___1gaUl {
  text-align: center;
  padding-top: 24px;
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.sdkBg___1ZNGz .main___bQTi0 .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 {
  display: flex;
  margin-top: 24px;
}
.sdkBg___1ZNGz .main___bQTi0 .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 .chinese_button_1etter___Ocy1q {
  margin: 0 auto;
  width: 140px;
  height: 50px;
  background-color: #5187d5;
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  line-height: 45px;
  border: 0px;
  border-radius: 4px;
}
.sdkBg___1ZNGz .main___bQTi0 .chinese_logos___teXRx .chinese_logos_one___1PO7C .chinese_button___14GZ6 .chinese_button_1etter___Ocy1q:hover {
  background-color: #6c9adb;
}
