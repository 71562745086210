/**
 * [class name] or [id] is not hashed in this file.
 * Sometime you use one or more antd components, and you need
 * override the css, then you create this kind of .css file.
 */
#bannerBox .slick-dots {
  height: 6px;
  text-align: right;
  padding-right: 15%;
  bottom: 25px;
}
#bannerBox .ant-carousel .slick-dots li {
  margin: 0 3px;
}
#bannerBox .ant-carousel .slick-dots li button {
  height: 4px;
  transition: none;
  width: 60px;
  border-radius: 0;
}
#bannerBox .ant-carousel .slick-dots li button:focus {
  opacity: 0.3;
}
#bannerBox .ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
}

#carouselBox .slick-dots-bottom {
  bottom: -15px;
}
#carouselBox .ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #D8D8D8;
  opacity: 1;
  margin: 0 4px;
}
#carouselBox .ant-carousel .slick-dots li.slick-active button {
  background: #5187D5;
}
